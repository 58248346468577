<template>
  <div class="headerBox" :class="sidebarExpand ? 'expandBox' : ''">
    <div class="commonHeader">
      <img
        src="@/assets/image/home/logo.png"
        alt="zxlogo"
        @click="$router.push({ name: 'home' })"
      />
      <el-button @click="openSidebar" v-if="!sidebarExpand"
        ><svg-icon icon-class="icon-expand" class="expandIcon"
      /></el-button>
      <el-button
        @click="
          sidebarExpand = false
          submenuExpand = false
        "
        v-else
        ><svg-icon icon-class="icon-close" class="expandIcon"
      /></el-button>
    </div>
    <div class="headerMcebianenus" v-if="sidebarExpand">
      <div class="headerSidebar">
        <div
          v-for="(item, index) in menus"
          :key="index"
          @click="selectMenu(item, $event)"
          :class="[
            { active: item.name === routerName },
            { active: item.types && routerName.split('-')[0] === 'product' }
          ]"
        >
          {{ item.label }}
          <div class="headerSubmenu" v-if="item.types && submenuExpand">
            <dl v-for="(type, i) in item.types" :key="i">
              <dt>{{ type.label }}</dt>
              <dd
                @click="goRouter(child.name)"
                v-for="(child, i) in type.items"
                :key="i"
              >
                {{ child.label }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonHeader',
  data() {
    return {
      sidebarExpand: false,
      submenuExpand: false,
      menus: [
        {
          name: 'home',
          label: '首页'
        },
        {
          label: '产品中心',
          types: [
            {
              label: '数字证书',
              items: [
                {
                  name: 'product-orgCert',
                  label: '机构证书'
                },
                {
                  name: 'product-personCert',
                  label: '个人证书'
                }
              ]
            },
            {
              label: '电子签章',
              items: [
                {
                  name: 'product-sealBox',
                  label: '智能印章一体机'
                },
                {
                  name: 'product-eSeal',
                  label: '电子签章系统'
                },
                {
                  name: 'product-electronicSignature',
                  label: '电子签章客户端'
                }
              ]
            },
            {
              label: '数据安全与密码服务',
              items: [
                {
                  name: 'product-verifySign',
                  label: '签名验签'
                },
                {
                  name: 'product-timestamp',
                  label: '可信时间戳'
                }
              ]
            },
            {
              label: '移动端数字密码服务',
              items: [
                {
                  name: 'product-mobileShield',
                  label: '手机盾'
                }
              ]
            }
          ]
        },
        {
          name: 'helpCenter',
          label: '帮助中心'
        },
        {
          name: 'aboutUs',
          label: '关于豸信'
        }
      ],
      routerName: ''
    }
  },
  methods: {
    goRouter(name) {
      this.sidebarExpand = false
      this.submenuExpand = false
      this.$router.push({ name: name })
    },
    selectMenu(item, e) {
      let sidebar = e.target.parentNode.children
      for (let i = 0; i < sidebar.length; i++) {
        sidebar[i].className = ''
      }
      e.target.className = 'active'
      item.types ? (this.submenuExpand = true) : this.goRouter(item.name)
    },
    openSidebar() {
      this.sidebarExpand = true
      this.routerName.split('-')[0] === 'product'
        ? (this.submenuExpand = true)
        : (this.submenuExpand = false)
    }
  },
  watch: {
    $route: {
      handler(to) {
        // this.submenuExpand = false
        if (
          to.path.split('/')[1] === '' ||
          to.path.split('/')[1] === null ||
          to.path.split('/')[1] === undefined
        ) {
          this.routerName = 'home'
        } else {
          this.routerName = to.path.split('/')[1]
          if (to.path.split('/')[1].split('-')[0] === 'product') {
            this.submenuExpand = true
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.headerBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 999;
}
.expandBox {
  width: 7.5rem;
  right: 0;
  bottom: 0;
}
.commonHeader {
  width: 100%;
  height: 0.88rem;
  padding: 0 0.32rem;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 0.74rem;
    height: 0.54rem;
  }
  .el-button {
    border: none;
    padding: 0;
  }
}
.headerMcebianenus {
  width: 7.5rem;
  // height: 100%;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(24px);
  position: fixed;
  top: 0.88rem;
  bottom: 0;
  z-index: 99;
}
.headerSidebar {
  width: 2.3rem;
  height: 100%;
  background: #f1f5ff;
  > div {
    display: inline-block;
    width: 100%;
    height: 1.12rem;
    line-height: 1.12rem;
    text-align: center;
    font-size: 0.28rem;
    color: #4f5262;
    letter-spacing: 1px;
  }
  .active {
    color: #ffffff;
    background: #1c67f3;
  }
}
.expandIcon {
  color: #1c67f3;
  font-size: 0.36rem;
}
.headerSubmenu {
  width: 5.2rem;
  height: 100%;
  padding: 0 0.24rem 0 0.48rem;
  box-sizing: border-box;
  position: absolute;
  top: 0.01rem;
  right: 0;
  z-index: 999;
  dl {
    text-align: left;
    border-bottom: 1px solid #ffffff;
    margin-top: 0.4rem;
    &:last-child {
      border-bottom: none;
    }
  }
  dt {
    font-size: 0.28rem;
    color: #8c92a7;
    line-height: 0.4rem;
    margin-bottom: 0.4rem;
    letter-spacing: 1px;
  }
  dt.active {
    background: none;
    color: #8c92a7;
  }
  dd {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.4rem;
    margin-bottom: 0.4rem;
  }
}
</style>
