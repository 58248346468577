import request from '@/utils/request'
// 查询内容类目
export function getContentcategory (params) {
  return request({
    url: '/portal/contentcategory/list',
    method: 'get',
    params
  })
}
// 帮助中心内容查询
export function getHelpcontentList (params) {
  return request({
    url: '/portal/helpcontent/list',
    method: 'get',
    params
  })
}
// 帮助中心内容详情
export function getHelpcontentInfo (params) {
  return request({
    url: '/portal/helpcontent/info',
    method: 'get',
    params
  })
}
// 下载中心内容查询
export function getDownloadcontentList (params) {
  return request({
    url: '/portal/downloadcontent/list',
    method: 'get',
    params
  })
}
// 下载中心内容详情
export function getDownloadcontentInfo (params) {
  return request({
    url: '/portal/downloadcontent/info',
    method: 'get',
    params
  })
}
// 获取文件
export function getDownloadcontentFile (data, onDownloadProgress) {
  return request({
    url: '/portal/downloadcontent/get-file',
    method: 'post',
    onDownloadProgress,
    data
  })
}
// 提交项目咨询
export function projectconsult (data) {
  return request({
    url: '/portal/projectconsult/save',
    method: 'post',
    data
  })
}
// 查询新闻列表
export function getNewsList (params) {
  return request({
    url: '/portal/news/list_page',
    method: 'get',
    params
  })
}
// 查询新闻详情
export function getNewsDetail (params) {
  return request({
    url: 'portal/news/detail',
    method: 'get',
    params
  })
}
// 移动端支付
export function getPayInfo (params) {
  return request({
    url: '/portal/api/trans/pay-info',
    method: 'get',
    params
  })
}
// 移动端支付跳转微信支付
export function getPayH5 (params) {
  return request({
    url: '/portal/api/trans/pay-h5',
    method: 'post',
    params
  })
}