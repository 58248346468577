import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// import VueCookie from 'vue-cookie'
Vue.use(Vuex)

const state = {
  token: sessionStorage.getItem('token'),
  businessListData: null
}
const actions = {

}
const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
    if (token) {
      sessionStorage.setItem('token', token)
    } else {
      sessionStorage.clear()
    }

  },
  SET_BUSINESSLISTDATA (state, businessListData) {
    state.businessListData = businessListData
  }
}
const store = new Vuex.Store({
  getters,
  actions,
  state,
  mutations,
  strict: true
})

export default store