<template>
  <div class="footerBox" v-if="scrollDistance>0">
    <div class="commonFooter">
      <a href="tel:4000302013"><svg-icon class="icon" icon-class="sidebar-tel" />电话咨询</a>
      <a href="javascript:;" @click="goTop"><svg-icon class="icon" icon-class="icon-gotop" />回到顶部</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonFooter',
  data () {
    return {
      scrollDistance: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      this.scrollDistance = window.pageYOffset || document.documentElement.scrollTop; // 获取滚动距离
    },
    goTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
  }
}
</script>

<style lang="scss" scoped>
.footerBox {
  width: 100%;
  position: fixed;
  left: 50%;
  bottom: 0;
  z-index: 99;
  transform: translateX(-50%);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
.commonFooter {
  width: 7.5rem;
  height: 0.97rem;
  margin: 0 auto;
  background: #ffffff;
  padding: 0 0.88rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    font-size: 0.24rem;
    color: #233c56;
    line-height: 0.33rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .icon {
      font-size: 0.3rem;
      color: #367bfc;
      margin-bottom: 0.08rem;
    }
  }
  .el-button {
    border: none;
    font-size: 0.24rem;
    color: #233c56;
    line-height: 0.33rem;
    ::v-deep span {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .icon {
      font-size: 0.3rem;
      color: #367bfc;
      margin-bottom: 0.08rem;
    }
  }
}
</style>