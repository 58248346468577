import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
  },
  {
    path: '/product-orgCert',
    name: 'product-orgCert',
    component: () => import('@/views/portal/productCenter/orgCert'),
  }, {
    path: '/product-personCert',
    name: 'product-personCert',
    component: () => import('@/views/portal/productCenter/personCert'),
  },
  {
    path: '/product-timestamp',
    name: 'product-timestamp',
    component: () => import('@/views/portal/productCenter/timesTamp'),
  },
  {
    path: '/product-mobileShield',
    name: 'product-mobileShield',
    component: () => import('@/views/portal/productCenter/mobileShield'),
  },
  {
    path: '/product-sealBox',
    name: 'product-sealBox',
    component: () => import('@/views/portal/productCenter/sealBox'),
  },
  {
    path: '/product-electronicSignature',
    name: 'product-electronicSignature',
    component: () => import('@/views/portal/productCenter/electronicSignature'),
  },
  {
    path: '/product-eSeal',
    name: 'product-eSeal',
    component: () => import('@/views/portal/productCenter/eSeal'),
  },
  {
    path: '/product-verifySign',
    name: 'product-verifySign',
    component: () => import('@/views/portal/productCenter/verifySign'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/portal/aboutZx/aboutUs'),
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: () => import('@/views/portal/helpCenter/helpCenter'),
  }, {
    path: '/helpList',
    name: 'helpList',
    component: () => import('@/views/portal/helpCenter/helpList'),
  },
  {
    path: '/paymentH5',
    name: 'paymentH5',
    component: () => import('@/views/paymentH5/index'),
  },
  {
    path: '/404',
    component: () => import('./../components/404.vue'),
    name: 'notfound',
  },
  {
    path: '*',
    redirect: '/404'
  },
]
const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
    x: 0
  }),
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  //    路由发生变化改变description和keyword
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }

  next()
})
router.afterEach(() => {
  // console.log(to, from, next)
})
Vue.use(VueRouter)
export default router